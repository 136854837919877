var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-media', {
    staticClass: "border-bottom pb-4",
    attrs: {
      "tag": "li"
    },
    scopedSlots: _vm._u([{
      key: "aside",
      fn: function fn() {
        return [_c('b-img', {
          attrs: {
            "src": _vm.itemData.imageLink || _vm.itemData.custom.shopImage,
            "width": "64",
            "alt": "placeholder"
          }
        })];
      },
      proxy: true
    }])
  }, [_c('h6', {
    staticClass: "mt-0 mb-1 text-left"
  }, [_vm._v(" " + _vm._s(_vm.itemData.name || _vm.itemData.providerName) + " ")]), _c('p', {
    staticClass: "mb-0 text-left"
  }, [_vm._v(" " + _vm._s(_vm.itemData.description || _vm.itemData.custom.shopNumber) + " ")])]);
}
var staticRenderFns = []

export { render, staticRenderFns }