<template>
  <b-media tag="li" class="border-bottom pb-4">
    <template #aside>
      <b-img
        :src="itemData.imageLink || itemData.custom.shopImage"
        width="64"
        alt="placeholder"
      ></b-img>
    </template>
    <h6 class="mt-0 mb-1 text-left">
      {{ itemData.name || itemData.providerName }}
    </h6>
    <p class="mb-0 text-left">
      {{ itemData.description || itemData.custom.shopNumber }}
    </p>
  </b-media>
</template>
<script>
export default {
  name: "order-item",
  props: {
    itemData: {
      default: () => {},
      type: Object,
    },
  },
};
</script>
